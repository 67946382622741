<div class="main-content">
  <div class="container-fluid">
    <body>
      <div class="container">
        <div class="card">
          <div class="card-header-rove">
              <h4 class="title text-center">Booking</h4>
          </div>
          <div class="card-body">
            <p class="text-center">{{transaksi.travel}}</p>
            <p class="text-center">{{haritgljam}}</p>
            <hr style="height:1px;border:none;color:#26B5A0;background-color:#26B5A0;" />
            <div class="row">
              <div class="col-md-4">
                <h4 class="text-center"><b>{{dariKota}}</b></h4>
                <h4 class="text-center">{{transaksi.dariSpesifik}}</h4>
              </div>
              <div class="col-md-4 text-center">
                <p>menuju</p>
              </div>
              <div class="col-md-4">
                <h4 class="text-center"><b>{{menujuKota}}</b></h4>
                <h4 class="text-center">{{transaksi.menujuSpesifik}}</h4>
              </div>
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label>Pembayaran</mat-label>
                <mat-select [(ngModel)]="vaSelected" name="Pembayaran">
                  <mat-option *ngFor="let va of vas " [value]="va">
                    {{va}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="col">
                <div class="row">
                  <mat-form-field appearance="outline" class="col">
                    <mat-label>Jumlah Penumpang</mat-label>
                    <mat-select [(ngModel)]="selectedPenumpang" name="Pembayaran">
                      <mat-option *ngFor="let va of penumpang" [value]="va">
                        {{va}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <p class="col">
                    Total Bayar:
                    <span style="font-weight:bold">
                      Rp {{(selectedPenumpang*transaksi.harga).toLocaleString(['ban', 'id'])}}.00
                    </span>
                  </p>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <mat-form-field appearance="outline" class="col">
                    <mat-label>No Handphone</mat-label>
                    <input matInput placeholder="0812345" type ="number" [(ngModel)]="hp">
                  </mat-form-field>
                  <font size="1" class="col">
                    *NOTE : Cantumkan nomor yang terdaftar pada aplikasi <b>whatsapp</b>
                  </font>
                </div>
              </div>
              <mat-form-field appearance="outline" class="col">
                <mat-label>Nama</mat-label>
                <input matInput placeholder="Nama" [(ngModel)]="nama">
              </mat-form-field>
              <!--  -->
            </div>
            <div class="d-flex flex-row-reverse">
              <div class="p-2">
                <button  mat-flat-button color="warn" (click) = "onBuyTravel()">Booking</button>
              </div>
            </div>
            <!-- <div class="row justify-content-end">
              <div class="col-2">
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </body>
  </div>
</div>

<!-- Event snippet for Purchase conversion page -->
<script>
  gtag('event', 'conversion', {
      'send_to': 'AW-472192451/n4eaCM3_gOwBEMOrlOEB',
      'transaction_id': ''
  });
</script>

