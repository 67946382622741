<mat-toolbar color="primary">
  <a href="/dashboard">
    <button mat-button aria-label="Example icon-button with menu icon">
      <img src="../../../assets/icons/Asset 11.svg" alt="icon travel rove" style="height: 50px; height: 50px;">
    </button>
  </a>
<span class="example-spacer"></span>
<button mat-icon-button class="example-icon" [matMenuTriggerFor]="menu" aria-label="Example icon-button with menu icon">
  <mat-icon>menu</mat-icon>
</button>
<mat-menu #menu="matMenu" color = "primary" xPosition="before">
  <a href="/payment-method">
    <button mat-menu-item>
    <span>Cara Pembayaran</span>
    <!-- <mat-icon>dialpad</mat-icon> -->
    </button>
  </a>
  <a href="/how-pay">
    <button mat-menu-item>
    <span>Cara Pemesanan</span>
    <!-- <mat-icon>voicemail</mat-icon> -->
    </button>
  </a>
  <a href="https://play.google.com/store/apps/details?id=am.rove.andro">
    <button mat-menu-item>
      <mat-icon>get_app</mat-icon>
      <span>Download Aplikasi</span>
    </button>
  </a>
</mat-menu>
</mat-toolbar>
