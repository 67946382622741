export class TransactionModel{
     travel : string="";
     tanggal: string="";
     tanggalMs: number = 0;
     harga: number= 0.0;
     hari: string = "";
     waktu : number = 0.0;
     idDari: string = "";
     idMenuju : string = "";
     dariKota: string ="";
     dariSpesifik: string ="";
     menujuKota: string ="";
     menujuSpesifik: string ="";
     dari: string="";
     menuju: string="";
     nama: string="";
     hp : string= "";
     jumlahKursi : number = 0.0;
     timemsOrder: number= 0;
     bayarVia:string = "";
     totalBayar: number = 0.0;
     lastPaymentDate: string = "";
     statusBayar: string = "Tunda"; //belum
     trxId : string ="";
     isToday: boolean = false;
     noPembayaran : string = ""
     via: string = "web"
     lastPaymentMs: number = 0.0
}