import { Component, OnInit } from '@angular/core';
import { PromosiModel } from '../models/promosi-model';
import { ActivatedRoute } from '@angular/router';
import { FirestoreService } from '../service/firestore.service';

@Component({
  selector: 'app-promosi',
  templateUrl: './promosi.component.html',
  styleUrls: ['./promosi.component.scss']
})
export class PromosiComponent implements OnInit {

  idPromosi: string;
  promoChoosen: PromosiModel;
  constructor(
    private route: ActivatedRoute,
    private firestore: FirestoreService,) {}
  async ngOnInit() {
    this.idPromosi = this.route.snapshot.params.idPromosi;
    if (!this.promoChoosen && this.idPromosi) {
      const a = (await this.firestore.getPromoId(this.idPromosi));
      a.forEach(d => {
        const promo = new PromosiModel();
        promo.deskripsi = d.get('deskripsi');
        promo.id = d.get('id');
        promo.images = d.get('images');
        promo.judul = d.get('judul')
        this.promoChoosen = promo;
      });
    }
  }

}
