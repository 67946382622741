import { Component, OnInit } from '@angular/core';
import { KotaModel } from '../models/kota-model';
import { ConvertDay, ConvertMonth } from '../helper/convert-days';
import { TransactionModel } from '../models/Transaction-model';
import { DataShareService } from '../service/data-share.service';
import { FirestoreService } from '../service/firestore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  transaksi
  // itemTravel: TravelModel;
  // destination: DestinationModel;
  vaSelected: string;
  selectedPenumpang: number = 1;
  vas: string[] = ['BNI', 'Permata', 'Mandiri', 'GOPAY'];
  penumpang: number[] = [1, 2, 3, 4, 5, 6];
  nama: string = '';
  hp: string = '';
  haritgljam = '';
  kotas: KotaModel[] = [];
  dariKota = '';
  menujuKota = '';
  dari: KotaModel;
  menuju: KotaModel;
  hari: string;
  lastPayment: Date;

  constructor(
    private datashare: DataShareService,
    private firestore: FirestoreService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar
  ) {
    this.route.queryParams.subscribe(z => {
      this.transaksi = z
    })
  }

  async ngOnInit() {
    try {
      const docs = await (await this.firestore.getKota()).toPromise();
      docs.forEach(doc => {
        var _kota = new KotaModel ()
        _kota.id = doc.get('id');
        _kota.idKota =  doc.get('idKota');
        _kota.kota = doc.id,
        this.kotas.push(_kota)
      })
      this.dari = this.kotas.find(x => x.kota == this.transaksi.dariKota);
      this.menuju = this.kotas.find(x => x.kota == this.transaksi.menujuKota);
      this.dariKota = this.dari.idKota;
      this.menujuKota = this.menuju.idKota;
      const date = new Date(Number(this.transaksi.date))
      this.hari = ConvertDay[date.getDay()]
      const tanggal = date.getDate();
      const bulan = ConvertMonth[date.getMonth()]
      const tahun = date.getFullYear()
      let waktu = '';
      if(+this.transaksi.waktu < 10) {
        waktu = `0${this.transaksi.waktu}.00`
      } else {
        waktu = `${this.transaksi.waktu}.00`
      }
      this.haritgljam = `${this.hari}, ${tanggal} ${bulan} ${tahun}, ${waktu}`;
      this.lastPayment = new Date();
       this.lastPayment.setHours(this.lastPayment.getHours() + 2);
    } catch (error) {
      this.router.navigate([`/dashboard`]);
    }
  }

  onBuyTravel() {
    console.log('ini on buy');

    if (this.vaSelected && this.selectedPenumpang && this.nama && this.hp) {
      const tglBerangkat = this.datePipe.transform(new Date(Number(this.transaksi.date)), 'dd MMMM yyyy');
      const lastPaymentDate = `${ConvertDay[this.lastPayment.getDay()]}, ${this.datePipe.transform(this.lastPayment, 'dd MMMM yyyy, HH:mm')}`;
      const msOrder = new Date().getTime();
      const transaction = new TransactionModel();
      transaction.dari = this.transaksi.dari;
      transaction.idDari = this.dari.idKota;
      transaction.dariKota = this.transaksi.dariKota;
      transaction.dariSpesifik = this.transaksi.dariSpesifik;
      transaction.idMenuju = this.menuju.idKota;
      transaction.menuju = this.transaksi.menuju;
      transaction.menujuKota = this.transaksi.menujuKota;
      transaction.menujuSpesifik = this.transaksi.menujuSpesifik;
      transaction.travel = this.transaksi.travel;
      transaction.waktu = +this.transaksi.waktu;
      transaction.hari = this.hari;
      transaction.harga = +this.transaksi.harga;
      transaction.tanggalMs = Number(this.transaksi.date);
      transaction.tanggal = tglBerangkat;
      transaction.nama = this.nama;
      transaction.hp = "0" + this.hp;
      transaction.bayarVia = this.vaSelected;
      transaction.jumlahKursi = this.selectedPenumpang;
      transaction.timemsOrder = msOrder
      transaction.totalBayar = transaction.jumlahKursi * transaction.harga
      transaction.lastPaymentDate = lastPaymentDate;
      transaction.lastPaymentMs = this.lastPayment.getTime()
      transaction.trxId = `${msOrder}${this.hp}`
      transaction.isToday = JSON.parse(this.transaksi.isToday)
      this.datashare.setTransaction(transaction);
      this.router.navigate([`/result`]);
    } else {
      this._snackBar.open("Mohon Isi Lengkap", "", {
        duration: 2000,
      });
    }
  }

}
