import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { DestinationModel } from '../models/destination-model';
import { TransactionModel } from '../models/Transaction-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })

export class FirestoreService {

    constructor(private firestore: AngularFirestore,
        private http:HttpClient) { }
    async getPolicies(destination: DestinationModel) {
        let dest = new DestinationModel()
        dest.dariKota = destination.dariKota
        dest.dari = destination.dari
        dest.dariSpesifik = destination.dariSpesifik
        dest.date = Number(destination.date)
        dest.menujuKota = destination.menujuKota
        dest.menuju = destination.menuju
        dest.menujuSpesifik = destination.menujuSpesifik
        dest.minJamTravel = Number(destination.minJamTravel)
        dest.isToday = Boolean(String(destination.isToday).toLowerCase() == 'true')
        const header = new HttpHeaders()
            .set('Content-Type', 'application/json')
        const t = await this.http.post('https://us-central1-roamx-6c177.cloudfunctions.net/list2',
            JSON.stringify(dest), {headers: header}).toPromise();
        return t
        // const doc = `${date.getDate()}${date.getMonth() + 1}`
        // if (isToday) {
        //     return this.firestore.collection('Jadwal')
        //     .doc(doc)
        //     .collection('Jadwal',  ref => ref.where('dari', '==', destination.dari)
        //                                 .where('menuju', '==', destination.menuju)
        //                                 .where('waktu', '>', date.getHours())
        //                                 .orderBy('waktu'))
        //     .valueChanges();
        // } else {
        //     return this.firestore.collection('Jadwal')
        //     .doc(doc)
        //     .collection('Jadwal',  ref => ref.where('dari', '==', destination.dari)
        //                                 .where('menuju', '==', destination.menuju)
        //                                 .orderBy('waktu'))
        //     .valueChanges();
        // }
    }

    async getKota() {
            return await this.firestore.collectionGroup('Kota')
                .get();
    }

    async getTurun() {
        return await this.firestore.collectionGroup('Turun2')
            .valueChanges();
    }

    async getPromo() {
      return await this.firestore.collectionGroup('Promosi')
            .get();
    }

    getPromoId(id: string) {
        return this.firestore.collection('Promosi', ref => ref.where('id', '==', id)).get().toPromise();
    }

    async setTransaksi(transaksi: TransactionModel) {
        return await this.firestore.collection('Transaksi').doc(transaksi.trxId).set(JSON.parse(JSON.stringify(transaksi)));
    }
}
