import { Component, OnInit, ViewChild } from '@angular/core';
import { Form, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { TurunModel } from '../models/turun-model';
import { PromosiModel } from '../models/promosi-model';
import { TransactionModel } from '../models/Transaction-model';
import { DestinationModel } from '../models/destination-model';
import { DataShareService } from '../service/data-share.service';
import { FirestoreService } from '../service/firestore.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {map, startWith} from 'rxjs/operators';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @ViewChild('Form', {static: false}) _Form: Form;
  checkTravel: FormGroup;
  minDate: Date;
  maxDate: Date;
  _dari: TurunModel;
  _menuju: TurunModel;
  option: TurunModel[] = [];
  optDari: Observable<TurunModel[]>;
  promosi: PromosiModel[] = [];
  optMenuju: Observable<TurunModel[]>;
  dari = new FormControl();
  menuju = new FormControl();
  message: DestinationModel;
  trx: TransactionModel;
  constructor(
    private fb: FormBuilder,
    private data: DataShareService,
    private datashare: DataShareService,
    private firestore: FirestoreService,
    private router: Router,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar) {
  }
  async ngOnInit() {
    this.checkTravel = this.fb.group({
      date: new FormControl('', Validators.required),
    });
    (await this.firestore.getTurun()).subscribe(data => {
      this.option = data as TurunModel[];
    });
      // console.log(doc.get('id'));
      // let a = doc.get('kota')
      // let b = doc.get('nama')
      // this.option.push(`${a} ${b}`)
    const promo = await (await this.firestore.getPromo()).toPromise();
    promo.forEach(doc =>{
      let a = new PromosiModel()
      a.id= doc.get('id');
      a.images= doc.get('images');
      a.deskripsi= doc.get('deskripsi');
      a.judul= doc.get('judul');
      this.promosi.push(a);
    })
    this.data.setPromosi(this.promosi);
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setDate(this.minDate.getDate() + 31);
    this.optDari = this.dari.valueChanges.pipe(startWith(''), map(x => this._filter(x)));
    this.optMenuju = this.menuju.valueChanges.pipe(startWith(''), map(x => this._filter(x)));
  }

  private _filter(value: string): TurunModel[] {
    if(typeof(value) == 'string') {
      const filterValue = value.toLowerCase();
    const option = this.option;
    const filtered = option.filter(x => x.nama.toLowerCase().includes(filterValue));
    return filtered;
    }    
  }
  displayName(turun){
    if(turun) return `${turun.kota} | ${turun.spesifik}`
    else return ''
  }
  onSearchTravel() {
    const a: DestinationModel = this.checkTravel.value;
    if(a.date && this._dari.kota && this._menuju.kota) {
      a.dariKota = this._dari.kota
      a.dariSpesifik = this._dari.spesifik
      a.dari = this._dari.nama
      a.menujuKota = this._menuju.kota
      a.menujuSpesifik = this._menuju.spesifik
      a.menuju = this._menuju.nama
      a.date = (new Date(a.date)).getTime()
      const date = this.datePipe.transform(new Date(a.date), 'dd-MM-yyyy')
      const now = new Date();
      const thisHour = new Date();
      // const nowDate = `${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`
      const nowDate = this.datePipe.transform(now, 'dd-MM-yyyy')
      this.trx = new TransactionModel();
      if (nowDate === date) {
        this.trx.isToday = true;
        a.isToday = true
        // thisHour.setHours(thisHour.getHours() + 4)
        a.minJamTravel = thisHour.getHours()
      } else {
        this.trx.isToday = false;
        a.isToday = false
        a.minJamTravel = thisHour.getHours()
      }
      this.datashare.setTransaction(this.trx)

      this.data.setDestination(a);
      this.router.navigate([`/listtravel`], { queryParams: a});
    } else {
      this._snackBar.open("Mohon Isi Lengkap", "", {
        duration: 2000,
      });
    }
  }

}
