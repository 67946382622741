import { Component, OnInit } from '@angular/core';
import { PermataBniBca, CustomerDetails, TransactionDetails, BankTransfer, CustomExpiry, Gopay, SubGopay, Mandiri, SubMandiri } from '../models/detail-tansaction';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DataShareService } from '../service/data-share.service';
import { FirestoreService } from '../service/firestore.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransactionModel } from '../models/Transaction-model';
import { ConvertDay, ConvertMonth } from '../helper/convert-days';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  transaction: TransactionModel;
  header:HttpHeaders;
  payment_type: string;
  // destination: DestinationModel;
  wait: boolean = false;
  constructor(
    private datashare: DataShareService,
    private firestore: FirestoreService,
    private router: Router,
    private http:HttpClient,
    private spinner: NgxSpinnerService,
  ) { }
  async ngOnInit() {
    this.spinner.show();
    this.header = new HttpHeaders()
    .set('Content-Type', 'application/json')
    // .set('Accept','application/json')
    // .set("Access-Control-Allow-Origin", "*")
    // .set("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS, PUT")
    // .set("Access-Control-Allow-Headers", "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With");
    this.datashare.transaction$.subscribe(x => {
      this.transaction = x;
    });
    // this.datashare.destination$.subscribe(x => this.destination = x);
    if(this.transaction.bayarVia !== '') {
      this.transaction.totalBayar = this.transaction.jumlahKursi * this.transaction.harga;
      const bayarVia = this.transaction.bayarVia;
      let detailTrx;
      switch (bayarVia) {
        case 'BNI':
          detailTrx = this.permataBniBca();
          break;
        case 'Permata':
          detailTrx = this.permataBniBca();
          break;
        case 'GOPAY':
          detailTrx = this.gopay();
          break;
        case 'Mandiri':
          detailTrx = this.mandiri();
          break;
        default:
          detailTrx = {}
          break;
      }
      if (bayarVia === 'BNI'){

        detailTrx = this.permataBniBca();
      }
      // const baseUrl = 'http://localhost:5000/roamx-6c177/us-central1/helloWorld'//tes
      // const baseUrl = 'http://localhost:5000/roamx-6c177/us-central1/SBpaymidrove'; //local
      // const baseUrl = 'https://us-central1-roamx-6c177.cloudfunctions.net/SBpaymidrove';//sandBox
      const baseUrl = 'https://us-central1-roamx-6c177.cloudfunctions.net/paymidrove' //prod
      if (this.transaction.totalBayar) {
        const _data = await this.http.post(baseUrl,
          JSON.stringify(detailTrx), {headers: this.header}).toPromise();
        this.payment_type = _data['payment_type'];
        switch (this.payment_type) {
          case 'echannel':
            this.transaction.noPembayaran = _data['bill_key']
            break;
          case 'bank_transfer':
            if (_data['permata_va_number'] !== undefined) {
              this.transaction.noPembayaran = _data['permata_va_number']
            } else {
              this.transaction.noPembayaran = _data['va_numbers'][0].va_number
            }
            break;
          case 'gopay':
            this.transaction.noPembayaran = _data['actions'][1].url
            break;
          default:
            this.transaction.noPembayaran = 'Error, Mohon Hubungi Kontak '
            break;
        }
      }
      await this.firestore.setTransaksi(this.transaction);
    }
    this.spinner.hide();
  }
  permataBniBca() {
    let a = new PermataBniBca();

    let customer_details= new CustomerDetails();
    customer_details.phone = this.transaction.hp;
    customer_details.email = 'noEmailForYou@hoaxx.com';

    let transaction_details = new TransactionDetails();
    transaction_details.gross_amount = this.transaction.totalBayar;
    transaction_details.order_id = this.transaction.trxId;

    let bank_transfer = new BankTransfer();
    bank_transfer.bank = this.transaction.bayarVia.toLowerCase();

    let custom_expiry = new CustomExpiry();
    custom_expiry.expiry_duration = 120;

    a.payment_type = "bank_transfer";
    a.customer_details = customer_details;
    a.transaction_details = transaction_details;
    a.bank_transfer = bank_transfer;
    a.custom_expiry = custom_expiry;
    return a;
  }
  gopay(){
    let a = new Gopay();

    let customer_details= new CustomerDetails();
    customer_details.phone = this.transaction.hp;
    customer_details.email = 'noEmailForYou@hoaxx.com';

    let transaction_details = new TransactionDetails();
    transaction_details.gross_amount = this.transaction.totalBayar;
    transaction_details.order_id = this.transaction.trxId;

    let custom_expiry = new CustomExpiry();
    custom_expiry.expiry_duration = 120;

    let _gopay = new SubGopay();
    _gopay.enable_callback = false;
    a.payment_type = "gopay";
    a.customer_details = customer_details;
    a.transaction_details = transaction_details;
    a.custom_expiry = custom_expiry;
    a.gopay = _gopay;
    return a;
  }

  mandiri() {
    let a = new Mandiri();

    let customer_details= new CustomerDetails();
    customer_details.phone = this.transaction.hp;
    customer_details.email = 'noEmailForYou@hoaxx.com';

    let transaction_details = new TransactionDetails();
    transaction_details.gross_amount = this.transaction.totalBayar;
    transaction_details.order_id = this.transaction.trxId;

    let custom_expiry = new CustomExpiry();
    custom_expiry.expiry_duration = 120;

    let _mandiri = new SubMandiri();
    _mandiri.bill_info1 = 'Rove';
    _mandiri.bill_info2 = 'Travel';

    a.payment_type = "echannel";
    a.customer_details = customer_details;
    a.transaction_details = transaction_details;
    a.custom_expiry = custom_expiry;
    a.echannel = _mandiri;
    return a;
  }

  bayarGopay() {
    window.location.href = this.transaction.noPembayaran;
  }

  setHariTglJam() {
    const date = new Date(this.transaction.tanggalMs)
    const hari = ConvertDay[date.getDay()]
    const tanggal = date.getDate();
    const bulan = ConvertMonth[date.getMonth()]
    const tahun = date.getFullYear()
    let waktu = '';
    if(this.transaction.waktu < 10) {
      waktu = `0${this.transaction.waktu}.00`
    } else {
      waktu = `${this.transaction.waktu}.00`
    }
    const haritgljam = `${hari}, ${tanggal} ${bulan} ${tahun}, ${waktu}`;
    return haritgljam;
  }

}
