export class PermataBniBca {
    payment_type: string;
    transaction_details: TransactionDetails;
    customer_details: CustomerDetails;
    bank_transfer: BankTransfer;
    custom_expiry: CustomExpiry;
  }
  export class TransactionDetails {
    gross_amount: number;
    order_id: string;
  }
  export class CustomerDetails {
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
  }
  export class BankTransfer {
    bank: string;
  }
  export class CustomExpiry {
    expiry_duration: number;
  }

  export class Gopay {
    payment_type: string;
    transaction_details: TransactionDetails;
    customer_details: CustomerDetails;
    gopay: SubGopay
    custom_expiry: CustomExpiry;
  }

  export class SubGopay {
    enable_callback: boolean;
  }

  export class Mandiri {
    payment_type: string;
    transaction_details: TransactionDetails;
    customer_details: CustomerDetails;
    echannel: SubMandiri;
    custom_expiry: CustomExpiry;
  }

  export class SubMandiri {
    bill_info1: string;
    bill_info2: string;
  }
  