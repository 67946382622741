<div class="main-content">
  <div class="container-fluid">
      <div class="d-flex justify-content-center">
        <body>
          <div class="col-md-8">
            <div *ngIf="promoChoosen" class="card">
              <div>
                <a href={{promoChoosen.images}}>
                  <img class="d-block w-100" src={{promoChoosen.images}} alt="promo rove travel">
                </a>
              </div>
              <br>
              <div class="d-flex justify-content-center">
                <div class="col">
                  <h3>
                    <b>
                      <font color="#26B5A0">
                        {{promoChoosen.judul}}
                      </font>
                    </b>
                  </h3>
                      <p>
                        {{promoChoosen.deskripsi}}
                      </p>
                </div>
                </div>
            </div>
          </div>
        </body>
      </div>
  </div>
</div>
