<div class="main-content">
  <div class="container-fluid">
      <body>
        <div class="container">
        <h3 class="text-center pb-4"><b>Tahapan Pemesanan</b></h3>
        <section id="features">
                <div class="row">
                    <div class="row">
                        <div class="col-md-3">
                            <h4><b>Tahap 1</b></h4>
                            <h5>Menentukan destinasi, menentukan tanggal keberangkatan, dan cek ketersediaan travel.</h5>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <img src="https://firebasestorage.googleapis.com/v0/b/roamx-6c177.appspot.com/o/Web%2Fss1.png?alt=media&token=ceb7ac18-7330-4e43-8ac1-fda6f961c906" class="img-fluid rounded mx-auto d-block" alt="pembayaran travel rove">
                        </div>

                    </div>
                    <div class="row justify-content-end">
                        <!-- <div class="col-sm-4"></div> -->
                        <div class="col-sm-3">
                            <h4><b>Tahap 2</b></h4>
                            <h5>Menentukan travel yang telah sesuai dengan jam dan harga.</h5>
                        </div>
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3">
                            <img src="https://firebasestorage.googleapis.com/v0/b/roamx-6c177.appspot.com/o/Web%2Fss2.png?alt=media&token=ab09b006-973c-4dac-86a8-4440dc54731a" class="img-fluid rounded mx-auto d-block" alt="cara pembayaran travel rove">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <h4><b>Tahap 3</b></h4>
                            <h5>Mengisi data pemesan, menentukan cara pembayaran, dan menentukan jumlah kursi yang diinginkan.</h5>
                        </div>
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3">
                            <img src="https://firebasestorage.googleapis.com/v0/b/roamx-6c177.appspot.com/o/Web%2Fss3.png?alt=media&token=76dfa656-1c5c-44de-a284-7d8067337a72" class="img-fluid rounded mx-auto d-block" alt="cara pembayaran travel rove">
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <!-- <div class="col-sm-4"></div> -->
                        <div class="col-sm-3">
                            <h4><b>Tahap 4</b></h4>
                            <h5>Menerima kode OTP dan validasi.</h5>
                        </div>
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3">
                            <img src="https://firebasestorage.googleapis.com/v0/b/roamx-6c177.appspot.com/o/Web%2Fss4.png?alt=media&token=87844de1-d399-476c-bb74-96fd40c57000" class="img-fluid rounded mx-auto d-block" alt="cara pembayaran travel rove">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <h4><b>Tahap 5</b></h4>
                            <h5>Melakukan pembayaran.</h5>
                        </div>
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3">
                            <img src="https://firebasestorage.googleapis.com/v0/b/roamx-6c177.appspot.com/o/Web%2Fss5.png?alt=media&token=f0a03832-5987-46a0-a841-446d20c5a3df" class="img-fluid rounded mx-auto d-block" alt="cara pembayaran travel rove">
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <!-- <div class="col-sm-4"></div> -->
                        <div class="col-sm-3">
                            <h4><b>Selesai</b></h4>
                            <h5>Anda dapat melihat invoice pada halaman riwayat.</h5>
                        </div>
                        <div class="col-sm-1"></div>
                        <div class="col-sm-3">
                            <img src="https://firebasestorage.googleapis.com/v0/b/roamx-6c177.appspot.com/o/Web%2Fss6.png?alt=media&token=4a02f8d1-ce89-4c69-af30-1b080aa05c30" class="img-fluid rounded mx-auto d-block" alt="cara pembayaran travel rove">
                        </div>
                    </div>
                </div>
        </section>
        </div>
    </body>
  </div>
</div>
