import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DestinationModel } from '../models/destination-model';
import { TravelModel } from '../models/travel-model';
import { TransactionModel } from '../models/Transaction-model';
import { PromosiModel } from '../models/promosi-model';

@Injectable({
  providedIn: 'root'
})

export class DataShareService {
  listPromosi: PromosiModel[] = [];
  private destination = new BehaviorSubject(new DestinationModel());
  private itemTravel = new BehaviorSubject(new TravelModel());
  private transaction = new BehaviorSubject(new TransactionModel());
  private promosi = new BehaviorSubject(this.listPromosi)

  destination$ = this.destination.asObservable();
  itemTravel$ = this.itemTravel.asObservable()
  transaction$ = this.transaction.asObservable();
  promosi$ = this.promosi.asObservable().toPromise();

  constructor() { }
  setDestination(d: DestinationModel) {
    this.destination.next(d)
    console.log('in datashareservice');
  }
  getDestination(): Observable<any> {
    return this.destination$;
  }
  setItemTravel(d: TravelModel) {
    this.itemTravel.next(d)
    console.log('in datashareservice');
  }
  setTransaction(t: TransactionModel) {
    this.transaction.next(t);
  }
  setPromosi(d: PromosiModel[]) {
    this.promosi.next(d);
  }
}
