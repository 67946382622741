export class DestinationModel {
    public dariKota: string;
    public dariSpesifik: string;
    public dari: string;
    public menujuKota: string;
    public menujuSpesifik: string;
    public menuju: string;
    public date: number;
    public isToday: boolean;
    public minJamTravel : number;
    public via: 'web'
}