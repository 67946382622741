import { Component, OnInit } from '@angular/core';
import { DestinationModel } from '../models/destination-model';
import { TravelModel } from '../models/travel-model';
import { TransactionModel } from '../models/Transaction-model';
import { DataShareService } from '../service/data-share.service';
import { FirestoreService } from '../service/firestore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConvertDay, ConvertMonth } from '../helper/convert-days';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list-travel',
  templateUrl: './list-travel.component.html',
  styleUrls: ['./list-travel.component.scss']
})
export class ListTravelComponent implements OnInit {

  destination: DestinationModel;
  travelData: TravelModel[] = [];
  asd: string;
  trx: TransactionModel
  date: string;
  thisHour: Date;
  wait: boolean = false;
  constructor(
    private datashare: DataShareService,
    private firestore: FirestoreService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
  ) {
    this.route.queryParams.subscribe(z => {
      this.destination = z as DestinationModel
    })
  }

  ngOnInit() {
    this.spinner.show();
    this.setData(this.destination)
    this.wait = true;
  }

  setData(x: DestinationModel) {
    this.firestore.getPolicies(x).then((data: any) => {
      this.travelData = data.data as TravelModel[];
      this.spinner.hide();
      })
  }

  setHariTglJam(item: TravelModel) {
    var t = new Date(Number(this.destination.date))
    const hari = ConvertDay[t.getDay()]
    const tanggal = t.getDate()
    const bulan = ConvertMonth[t.getMonth()]
    const tahun = t.getFullYear()
    let waktu = '';
    if(item.waktu < 10) {
      waktu = `0${item.waktu}.00`
    } else {
      waktu = `${item.waktu}.00`
    }
    const haritgljam = `${hari}, ${tanggal} ${bulan} ${tahun}, ${waktu}`;
    return haritgljam;
  }

  onBookingTravel(e: TravelModel) {
    if(!e.isFull) {
      this.datashare.setItemTravel(e)
      var y = {...this.trx, ...this.destination, ...e}
      this.router.navigate([`/booking`], { queryParams: y});
    }
  }

  onBackground(item: TravelModel) {
    if(item.isFull == true) {
      return '#ccc'
    } else {
      return ''
    }
  }

  onRef(item) {
    if(item.isFull == true) {
      return 'javascript:void(0)'
    } else {
      return 'booking'
    }
  }

}
