<div class="main-content">
  <div class="container-fluid">
    <body>
      <div class="container">
        <div class="card">
          <div class="card-header-rove">
            <h4 class="title text-center">Pembayaran</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <h4 class="text-center"><b>{{transaction.idDari}}</b></h4>
                <h4 class="text-center">{{transaction.dariSpesifik}}</h4>
              </div>
              <div class="col-md-4 text-center">
                <p>menuju</p>
              </div>
              <div class="col-md-4">
                <h4 class="text-center"><b>{{transaction.idMenuju}}</b></h4>
                <h4 class="text-center">{{transaction.menujuSpesifik}}</h4>
              </div>
            </div>
            <hr style="height:1px;border:none;color:#26B5A0;background-color:#26B5A0;" />
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                    <label class="label">Keberangkatan</label>
                    <p>{{setHariTglJam()}}</p>
                  </div>
                  <div class="col-md-4">
                    <label class="label">Cara Pembayaran</label>
                    <p>{{transaction.bayarVia}}</p>
                  </div>
                  <div class="col-md-4">
                    <label class="label">Status Pembayaran</label>
                    <p>{{transaction.statusBayar}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label class="label">Jumlah Penumpang</label>
                    <p>{{transaction.jumlahKursi}}</p>
                  </div>
                  <div *ngIf="payment_type != 'gopay'" class="col-md-4">
                    <label class="label">Nomor VA</label>
                    <p>{{transaction.noPembayaran}}</p>
                  </div>
                  <div class="col-md-4">
                    <label class="label">Batas Pembayaran</label>
                    <p>{{transaction.lastPaymentDate}}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <label class="label">Total Tagihan</label>
                <p> Rp {{(transaction.totalBayar).toLocaleString(['ban', 'id'])}}.00</p>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col"> -->
                <button *ngIf="payment_type == 'gopay'" mat-raised-button class="col btn btn-rove" color="warn" (click) = "bayarGopay()">Bayar Gopay</button>
              <!-- </div> -->
            </div>
            <font size="2">
              *NOTE : Jika pembayaran sudah dilakukan, pembaruan status akan dikirim pesan melalui Whatsapp.
                      Atau download aplikasi kami di <a href="https://play.google.com/store/apps/details?id=am.rove.andro">SINI</a>
            </font>
          </div>
        </div>
        <div class="col py-5">
          <img src="https://firebasestorage.googleapis.com/v0/b/roamx-6c177.appspot.com/o/Web%2Fweb-ads.webp?alt=media&token=18e19035-a4ed-42b0-9fc4-f6e293314044" class="img-fluid" alt="promosi aplikasi rove">
        </div>
      </div>
    </body>
  </div>
</div>
<ngx-spinner></ngx-spinner>
<!-- Event snippet for Purchase conversion page -->
<script>
  gtag('event', 'conversion', {
      'send_to': 'AW-472192451/n4eaCM3_gOwBEMOrlOEB',
      'transaction_id': ''
  });
</script>
