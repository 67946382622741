
  <div class="container-fluid">
    <body>
      <div class="main-content">
        <div class="column">
          <div class="card-header-danger">
            <p class="title text-center">Untuk saat ini belum bisa melayani pemesanan tiket karena ada perubahan sistem sampai saat yang tidak bisa ditentukan</p>
          </div>
          <div class="row">
            <div class="col-md-7">
              <carousel [isAnimated]="true" [interval]="2000">
                <slide *ngFor="let item of promosi">
                  <a href= "promosi/{{item.id}}">
                    <img src={{item.images}} alt={{item.id}} style="display: block; width: 100%;">
                  </a>
                </slide>
              </carousel>
            </div>
            <div class="col-md-5 py-5">
                <div class="card">
                    <div class="card-header-rove">
                        <h4 class="title text-center">Pesan Tiket</h4>
                    </div>
                    <div class="card-body">
                      <form [formGroup]="checkTravel"
                      #Form (submit) = "onSearchTravel()">
                      <div class="row">
                        <div class="col-md-1">
                            <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_back</mat-icon>
                        </div>
                        <div class="col-md-10">
                            <mat-form-field class="col">

                            <input [(ngModel)] = "_dari" type="text"
                              placeholder="Asal Keberangkatan"
                              matInput
                              [formControl] = "dari"
                              [matAutocomplete]="auto"
                              [readonly]="true">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName">
                              <mat-option *ngFor="let option of optDari | async" [value]="option">
                                <span>{{option.kota}}</span> |
                                <small>{{option.spesifik}}</small>
                              </mat-option>
                            </mat-autocomplete>
                            </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-1">
                            <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_forward</mat-icon>
                        </div>
                        <div class="col-md-10">
                            <mat-form-field class="col">
                              <input [(ngModel)] = "_menuju" type="text"
                                placeholder="Tujuan Keberangkatan"
                                matInput
                                [formControl] = "menuju"
                                [matAutocomplete]="autoo"
                                [readonly]="true">
                              <mat-autocomplete #autoo="matAutocomplete" [displayWith] = "displayName">

                                <mat-option *ngFor="let option of optMenuju | async" [value]="option">
                                  <span>{{option.kota}}</span> |
                                  <small>{{option.spesifik}}</small>
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                        </div>
                      </div>
                          <div class="row">
                              <div class="col-md-1">
                                  <mat-icon aria-hidden="false" aria-label="Example home icon" (click)="picker.open()">today</mat-icon>
                              </div>
                              <div class="col-md-10">
                                    <mat-form-field class="col">
                                      <mat-label>Tanggal (dd/mm/yyyy)</mat-label>
                                      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" formControlName="date" (click)="picker.open()" [readonly]="true">
                                      <mat-datepicker touchUi="true" #picker></mat-datepicker>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="d-flex flex-row-reverse">
                            <div class="p-2">
                              <button mat-flat-button type="submit" color="warn">Cari Travel</button>
                              <!-- <button mat-raised-button class="btn btn-danger" type="submit">Cari Travel</button> -->
                            </div>
                          </div>
                          <div class="clearfix"></div>
                      </form>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>

  <div hidden itemscope itemtype="http://schema.org/Product">
    <img itemprop="image" src="../../assets/icons/icon-96x96.png" alt="Rove"/>
    <meta itemprop="name" content="rove travel" />
    <link itemprop="image" href="../../assets/icons/icon-96x96.png" />
    <link itemprop="image" href="../../assets/icons/icon-96x96.png" />
    <link itemprop="image" href="../../assets/icons/icon-96x96.png" />
    <meta itemprop="description" content="Aplikasi Pemesanan Travel Antar Kota di Jawa Timur, Jogja, Jawa Tengah. Aplikasi Pertama di Jawa Timur. Bekerjasama dengan Travel Terpercaya di Setiap Kota" />
    <div itemprop="offers" itemtype="http://schema.org/AggregateOffer" itemscope>
      <meta itemprop="lowPrice" content="800,000" />
      <meta itemprop="highPrice" content="230,000" />
      <meta itemprop="offerCount" content="6" />
      <meta itemprop="priceCurrency" content="IDR" />
    </div>
    <div itemprop="aggregateRating" itemtype="http://schema.org/AggregateRating" itemscope>
      <meta itemprop="reviewCount" content="348" />
      <meta itemprop="ratingValue" content="4.4" />
    </div>
    <div itemprop="review" itemtype="http://schema.org/Review" itemscope>
      <div itemprop="author" itemtype="http://schema.org/Person" itemscope>
        <meta itemprop="name" content="Ivan Fadli Hakim" />
      </div>
      <div itemprop="reviewRating" itemtype="http://schema.org/Rating" itemscope>
        <meta itemprop="ratingValue" content="4" />
        <meta itemprop="bestRating" content="5" />
      </div>
    </div>
    <div itemprop="brand" itemtype="http://schema.org/Brand" itemscope>
      <meta itemprop="name" content="ROVE" />
    </div>
  </div>

