import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare var gtag

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Web-Consumer-v3';
  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('set', 'page', event.url);
        // gtag('config', 'UA-154541240-1');
      }
    });
  }
}
