<div class="main-content">
  <div class="container-fluid">
    <body>

      <div class="d-flex justify-content-center">
        <div class="col-md-8">
          <div class="card">
            <div>
              <a href="javascript:void(0);">
                <img class="d-block w-100" src="https://firebasestorage.googleapis.com/v0/b/roamx-6c177.appspot.com/o/promo%204.png?alt=media&token=efa4c5c0-ad82-4a66-aab8-a104272a4a7a" alt="metode pembayaran travel rove BNI Mandiri Gopay Permata">
              </a>
            </div>
            <br>
            <div class="d-flex justify-content-center">
              <div class="col">
                <h3>
                  <b>
                    <font color="#26B5A0">
                      Cara Pembayaran
                    </font>
                  </b>
                </h3>

                    <div>
                      <br>
  <h5><b>MANDIRI</b></h5>

  <h6><p>Tata Cara Membayar Melalui ATM</p></h6>
  <p>
    1. Masukkan PIN anda<br>
    2. Pilih BAYAR/BELI<br>
    3. Cari pilihan MULTI PAYMENT<br>
    4. Masukkan Kode Perusahaan 70012<br>
    5. Masukkan Kode Pelanggan 7001232133xxx679<br>
    6. Masukkan Jumlah Pembayaran sesuai dengan Jumlah Tagihan anda kemudian tekan 'Benar'<br>
    7. Pilih Tagihan Anda jika sudah sesuai tekan YA<br>
    8. Konfirmasikan tagihan anda apakah sudah sesuai lalu tekan YA<br>
    9. Transaksi susah selesai, silahkan simpan bukti pembayaran anda<br>
    10. Pastikan status pembayaran anda telah berganti menjadi LUNAS pada menu RIWAYAT, jika melakukan pemesanan melalui aplikasi. Namun jika memesan melalui website, maka tunggu konfirmasi kami mengenai status pembayaran melalui Whatsapp.<br>
  </p>

  <h6><p>Pembayaran Mandiri Virtual Account dengan Mandiri Online</p></h6>
  <p>
    1. Login Mandiri Online dengan memasukkan username dan password<br>
    2. Pilih menu PEMBAYARAN<br>
    3. Pilih menu MULTI PAYMENT<br>
    4. Cari Penyedia Jasa 'MIDRANS'<br>
    5. Masukkan Nomor Virtual Account dan nominal yang akan dibayarkan, lalu pilih Lanjut<br>
    6. Setelah muncul tagihan, pilih Konfirmasi<br>
    7. Masukkan PIN/ challange code token<br>
    8. Transaksi susah selesai, silahkan simpan bukti pembayaran anda<br>
    9. Pastikan status pembayaran anda telah berganti menjadi LUNAS pada menu RIWAYAT, jika melakukan pemesanan melalui aplikasi. Namun jika memesan melalui website, maka tunggu konfirmasi kami mengenai status pembayaran melalui Whatsapp.<br>
  </p>


  <h5><b>PERMATA</b></h5>

  <h6><p>Pembayaran Melalui ATM Permata</p></h6>
  <p>
    1. Masukkan PIN<br>
    2. Pilih menu TRANSAKSI LAINNYA<br>
    3. Pilih menu PEMBAYARAN<br>
    4. Pilih menu PEMBAYARAN LAINNYA<br>
    5. Pilih menu VIRTUAL ACCOUNT<br>
    6. Masukkan nomor VIRTUAL ACCOUNT yang tertera pada halaman konfirmasi, dan tekan BENAR<br>
    7. Pilih rekening yang menjadi sumber dana yang akan didebet, lalu tekan YA untuk konfirmasi transaksi<br>
    8. Transaksi susah selesai, silahkan simpan bukti pembayaran anda<br>
    9. Pastikan status pembayaran anda telah berganti menjadi LUNAS pada menu RIWAYAT, jika melakukan pemesanan melalui aplikasi. Namun jika memesan melalui website, maka tunggu konfirmasi kami mengenai status pembayaran melalui Whatsapp.<br>
  </p>
  <h6><p>Pembayaran Melalui Permata Mobile</p></h6>
  <p>
    1. Masukkan User ID & Password<br>
    2. Pilih PEMBAYARAN TAGIHAN<br>
    3. Pilih VIRTUAL ACCOUNT<br>
    4. Masukkan 16 digit nomor VIRTUAL ACCOUNT yang tertera pada halaman konfirmasi<br>
    5. Masukkan nominal pembayaran sesuai dengan yang ditagihkan<br>
    6. Muncul Konfirmasi pembayara, lalu masukkan otentikasi transaksi/token<br>
    7. Transaksi susah selesai, silahkan simpan bukti pembayaran anda<br>
    8. Pastikan status pembayaran anda telah berganti menjadi LUNAS pada menu RIWAYAT, jika melakukan pemesanan melalui aplikasi. Namun jika memesan melalui website, maka tunggu konfirmasi kami mengenai status pembayaran melalui Whatsapp.<br>
  </p>

  <h5><b>BNI</b></h5>

  <h6><p>Pembayaran Melalui ATM </p></h6>
  <p>
    1. Masukkan PIN ATM Anda<br>
    2. Pilih MENU LAINNYA<br>
    3. Pilih TRANSFER<br>
    4. Pilih Jenis rekening yang akan anda gunakan (Contoh: "Dari Rekening Tabungan")<br>
    5. Pilih VIRTUAL ACCOUNT BILLING<br>
    6. Masukkan nomor VIRTUAL ACCOUNT <br>
    7. Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi<br>
    8. Konfirmasi, apabila telah sesuai, lanjutkan transaksi<br>
    9. Transaksi susah selesai, silahkan simpan bukti pembayaran anda
    10. Pastikan status pembayaran anda telah berganti menjadi LUNAS pada menu RIWAYAT, jika melakukan pemesanan melalui aplikasi. Namun jika memesan melalui website, maka tunggu konfirmasi kami mengenai status pembayaran melalui Whatsapp.<br>
  </p>

  <h6><p>Pembayaran Melalui Mobile Banking BNI</p></h6>
  <p>
    1. Masukkan user ID dan password<br>
    2. Pilih menu TRANSFER<br>
    3. Pilih menu VIRTUAL ACCOUNT BILLING kemudian pilih rekening debet<br>
    4. Masukkan nomor VIRTUAL ACCOUNT pada menu INPUT BARU<br>
    5. Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi<br>
    6. Konfirmasi transaksi dan masukkan Password Transaksi<br>
    7. Transaksi susah selesai, silahkan simpan bukti pembayaran anda<br>
    8. Pastikan status pembayaran anda telah berganti menjadi LUNAS pada menu RIWAYAT, jika melakukan pemesanan melalui aplikasi. Namun jika memesan melalui website, maka tunggu konfirmasi kami mengenai status pembayaran melalui Whatsapp.<br>
  </p>

  <h5><b>GOPAY</b></h5>
  <p>
  1. Pilih BAYAR VIA GOPAY pada menu PESAN TRAVEL<br>
  2. Pilih buka dengan GOJEK<br>
  3. Setelah terbuka aplikasi GOJEK, lakukan konfirmasi pembayaran <br>
  4. Masukkan Password Transaksi<br>
  5. Transaksi susah selesai, silahkan simpan bukti pembayaran anda<br>
  6. Pastikan status pembayaran anda telah berganti menjadi LUNAS pada menu RIWAYAT, jika melakukan pemesanan melalui aplikasi. Namun jika memesan melalui website, maka tunggu konfirmasi kami mengenai status pembayaran melalui Whatsapp.<br>
  </p>
  </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </body>
  </div>
</div>
