<footer class="footer ">
  <div class="container-fluid">
      <footer>
          <div class="container">
            <div class="row">

              <div class="col-lg-4 col-md-6">
                <h3 class="text-center">Site Map</h3>
                  <div class="col">
                  <li class="text-left">
                    <a href="/payment-methode">
                      Pembayaran
                    </a>
                  </li>
                  <li class="text-left">
                    <a href="/how-pay">
                      Pemesanan
                    </a>
                  </li>
                  </div>
                <div class="list-unstyled socila-list d-flex justify-content-around">
                  <a href="https://www.instagram.com/rove.biz.id/"><img src="../../../assets/img/ic_ig.png" class="fit-image" alt="instagram travel rove icon" /></a>
                  <a href="http://wa.me/6281343895993"><img src="../../../assets/img/ic_wa.png" class="fit-image" alt="whatsapp travel rove icon" /></a>
                  <a href="mailto:our.rove@gmail.com"><img src="../../../assets/img/ic_mail.png" class="fit-image" alt="email travel rove icon" /></a>
                  <a href="https://www.facebook.com/rovebizid-108668547458299/"><img src="../../../assets/img/ic_fb.png" class="fit-image" alt="facebook travel rove icon" /></a>
                  <a href="https://twitter.com/travel_rove"><img src="../../../assets/img/ic_tw.png" class="fit-image" alt="twitter travel rove icon" /></a>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <h3 class="text-center">Our Patner</h3>
                <div class="d-flex justify-content-around">
                  <a href="https://firebase.google.com/?hl=id" class="">
                    <img src="../../../assets/img/ic_firebase.png" alt="firebase rove travel support" class="img-center"/>
                  </a>
                  <a href="https://midtrans.com/" class="pull-left">
                    <img src="../../../assets/img/ic_midtrans.png" alt="midtrans rove travel support" class="img-center"/>
                  </a>
                </div>
              </div>
              <div class="col-lg-4">
                <h3 class="text-center">Get Apps</h3>
                <div class="text-center">
                  <a href="https://play.google.com/store/apps/details?id=am.rove.andro">
                    <img class="img-thumbnail" src="../../../assets/img/gplay.png" alt="android application travel rove icon" />
                  </a>
                </div>
              </div>

            </div>
            <div class="col pt-5">
              <h1 class="text-center">Travel Partner Rove</h1>
              <div class="d-flex flex-wrap justify-content-around">
                <div class="media text-center px-3">
                  <div class="media-body text-center">
                    <h4 class="media-heading bold">Tulungagung</h4>
                    <p>Friends</p>
                  </div>
                </div>
                <div class="media text-center px-3">
                  <div class="media-body">
                    <h4 class="media-heading bold">Yogyakarta</h4>
                    <p>Nabawi, Joglosemar</p>
                  </div>
                </div>
                <div class="media text-center px-3">
                  <div class="media-body">
                    <h4 class="media-heading bold">Semarang</h4>
                    <p>Nabila</p>
                  </div>
                </div>
                <div class="media text-center px-3">
                  <div class="media-body">
                    <h4 class="media-heading bold">Malang</h4>
                    <p>DRA Travel, Rafka, King, Transuperindo</p>
                  </div>
                </div>
                <div class="media text-center px-3">
                  <div class="media-body">
                    <h4 class="media-heading bold">Blitar</h4>
                    <p>Friends, Joy</p>
                  </div>
                </div>
                <div class="media text-center px-3">
                  <div class="media-body">
                    <h4 class="media-heading bold">Madiun</h4>
                    <p>Sigma</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            Copyright &copy; 2020 <span>Rove</span>, Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          </div>
        </footer>
  </div>
</footer>
