import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect-gopay',
  templateUrl: './redirect-gopay.component.html',
  styleUrls: ['./redirect-gopay.component.scss']
})

export class RedirectGopayComponent implements OnInit {
  tref
  amount
  constructor(private route:ActivatedRoute) {
    this.route.queryParams.subscribe(z => {
      this.tref = z.tref
      this.amount = z.amount
    })
  }

  ngOnInit(): void {
    // console.log(this.urlGopay);
    // window.location.href = "";
  }

  bayarGopay(){
    window.location.href = `gojek://gopay/merchanttransfer?tref=${this.tref}&amount=${this.amount}&activity=GP:RR&callback_url=`;
  }

}
