<div class="main-content">
  <div class="container-fluid">
    <body>
      <div class="container">
        <div *ngIf="wait==true">
          <div *ngIf="travelData.length > 0; else emptyResult">
            <div *ngFor="let item of travelData" class="row justify-content-center">
              <div class="col-md-8">
                <a href="javascript:void(0);">
                  <mat-card [style.backgroundColor]= "onBackground(item)" class="example-card" (click) = "onBookingTravel(item)">
                    <mat-card-title>
                      <b>
                        <font color="#26B5A0">{{item.travel}}</font>
                      </b>
                    </mat-card-title>
                    <mat-card-content>
                      <div class="col">
                        <div class="text-center">
                          <span style="font-weight:bold">
                            Rp {{item.harga.toLocaleString(['ban', 'id'])}},00
                          </span>
                        </div>
                        <div class="text-center"><b>{{setHariTglJam(item)}}</b></div>
                      </div>
                      <div class="col">
                        <div class="text-center">
                          <span style="font-weight:normal; font-size: smaller;">
                            Tersedia {{item.availableSeat}} Kursi
                          </span>
                        </div>
                      </div>
                      <hr style="height:1px;border:none;color:#26B5A0;background-color:#26B5A0;" />
                      <div class="row">
                        <div class="col-4">
                          <p class="text-center">
                            <span style="font-weight:bold">
                              {{item.dariKota}}
                            </span><br>
                            <font size="2">
                              {{item.dariSpesifik}}
                            </font>
                          </p>
                        </div>
                        <div class="col-4 text-center">
                          <p>menuju</p>
                        </div>
                        <div class="col-4">
                          <p class="text-center">
                            <span style="font-weight:bold">
                              {{item.menujuKota}}
                            </span><br>
                            <font size="2">
                              {{item.menujuSpesifik}}
                            </font>
                          </p>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </a>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
</div>
<ngx-spinner></ngx-spinner>
<ng-template #emptyResult>
  <div class="row justify-content-center">
    <div class="logo-img text-center">
      <img src="../../assets/icons/icon-72x72.png" alt="icon rove travel"/>
      <h3 class="text-center">Maaf</h3>
      <h3 class="text-center">hasil penca<span class="rove">R</span>ian <span class="rove">0</span>, kami belum bekerjasama dengan tra<span class="rove">V</span>el yang melayani rut<span class="rove">E</span> untuk pencarian anda</h3>
      <h3 class="text-center">Silahkan Hubungi Kami untuk Menyarankan Travel dan Rute yang Anda Butuhkan</h3>
  </div>
  </div>
</ng-template>
<!-- Event snippet for Page view conversion page -->
<script>
  gtag('event', 'conversion', {'send_to': 'AW-472192451/CizeCKHPk-0BEMOrlOEB'});
</script>

