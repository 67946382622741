export class TravelModel {
    public dari: string;
    public dariKota: string;
    public dariSpesifik: string;
    public harga: number;
    public isFull: boolean;
    public menuju: string;
    public menujuKota: string;
    public menujuSpesifik: string;
    public travel: string;
    public waktu: number;
    public maxSeat: number;
    public bookedSeat: number;
    public availableSeat: number;
  }