import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ListTravelComponent } from './list-travel/list-travel.component';
import { BookingComponent } from './booking/booking.component';
import { ResultComponent } from './result/result.component';
import { PromosiComponent } from './promosi/promosi.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { HowPayComponent } from './how-pay/how-pay.component';
import { RedirectGopayComponent } from './redirect-gopay/redirect-gopay.component';


const routes: Routes = [{
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  { path: 'dashboard',      component: DashboardComponent },
  { path: 'listtravel',     component: ListTravelComponent},
  { path: 'booking',        component: BookingComponent},
  { path: 'result',         component: ResultComponent},
  { path: 'promosi/:idPromosi',        component: PromosiComponent},
  { path: 'payment-method', component: PaymentMethodComponent},
  { path: 'how-pay', component: HowPayComponent},
  { path: 'redirectGopay', component: RedirectGopayComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
