export enum ConvertDay {
    Minggu,
    Senin,
    Selasa,
    Rabu,
    Kamis,
    Jumat,
    Sabtu,  
  }

export enum ConvertMonth {
    Januari,
    Februari,
    Maret,
    April,
    Mei,
    Juni,
    Juli,
    Agustus,
    September,
    Oktober,
    November,
    Desember
}